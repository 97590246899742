import { Link as RouterLink } from '@tanstack/react-router'
import * as S from 'apps/cliniko/style'
import * as Icon from 'components/icon'
import { baselineOffset, lineHeight } from 'components/prose'
import { isExternal } from 'modules/router'
import * as _ from 'modules/util'
import { forwardRef, isValidElement } from 'react'

const Link = forwardRef(
  (
    {
      children,
      href,
      external = isExternal(href),
      icon = { right: external && <Icon.BoxArrowTopRight /> },
      rel,
      target,
      type,
      ...propsIn
    },
    ref
  ) => {
    const { left: iconLeft, right: iconRight } = isValidElement(icon) ? { left: icon } : icon

    const props = {
      css: [
        type === 'unstyled' ? resetStyles : styles({ type, iconLeft, iconRight, padding: true }),
      ],
      rel: _.joinStrings(external && 'nofollow noopener noreferrer', rel),
      target: target || _.when(external, '_blank'),
      ...propsIn,
    }

    return (
      <RouterLink ref={ref} to={href} {...props}>
        {iconLeft}
        {children}
        {iconRight}
      </RouterLink>
    )
  }
)

const resetStyles = {
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'none',
    color: 'inherit',
  },
}

export const styles = ({ type, iconLeft, iconRight, padding }) => [
  type !== 'inline' && {
    position: 'relative',
    bottom: baselineOffset,
    lineHeight,
  },
  (iconLeft || iconRight) && {
    '& > svg': {
      bottom: `-${baselineOffset}`,
      marginLeft: iconRight && S.unit(0.5),
      marginRight: iconLeft && S.unit(0.5),
    },
  },
  {
    color: _.cond(
      [type === 'plain', 'inherit'],
      [type === 'quiet', S.colors.grey(5)],
      [S.colors.pink()]
    ),
    textDecoration: type === 'inline' ? 'underline' : 'none',
    borderRadius: S.borderRadius,

    '&:hover': {
      color: S.colors.pink(),
      textDecoration: 'underline',
    },

    '&[data-focus-visible-added]': {
      outline: `2px solid ${S.colors.focus}`,
    },
  },
  padding && {
    paddingLeft: S.rem(3),
    paddingRight: S.rem(3),
    marginLeft: S.rem(-3),
    marginRight: S.rem(-3),
    paddingBottom: !(iconLeft || iconRight) && S.rem(1),
    marginBottom: !(iconLeft || iconRight) && S.rem(-1),
  },
]

export default Link
