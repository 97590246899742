import * as _ from 'modules/util'
import { forwardRef, useContext } from 'react'
import TooltipContext from './context'

export const Button = forwardRef(({ as: Button = 'button', ...props }, refIn) => {
  const tooltip = useContext(TooltipContext)
  const ref = _.useMergeRefs([tooltip.refs.setReference, refIn])
  return (
    <Button ref={ref} compact={tooltip.compact} {...props} {...tooltip.getReferenceProps(props)} />
  )
})
