import * as S from 'apps/cliniko/style'

const Page = ({ children, type, ...props }) => (
  <main css={pageStyles({ type })} {...props}>
    {children}
  </main>
)

const pageStyles = ({ type }) => [
  S.pagePadding,
  {
    background: 'white',
    flex: '1 0 auto',
  },
  type === 'index' && {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,

    [S.media.belowMedium]: {
      minHeight: `calc(100vh + ${S.unit(7)})`,

      '[role=table]': S.space.mx(-2),
    },

    '> header': S.space.mb(2),
  },
  type === 'form' && {
    width: '100%',
    maxWidth: S.sizes.pageLimitWidth,

    header: {
      [S.media.large]: S.space.mb(1),
    },
  },
  type === 'show' && {
    width: '100%',
    maxWidth: S.sizes.maxPaddedContent,

    [S.media.xlarge]: {
      width: 'fit-content',
      minWidth: S.sizes.maxPaddedContent,
      maxWidth: S.sizes.pageLimitWidth,
    },

    '> header': {
      ...S.space.mb(3),
      [S.media.large]: S.space.mb(4),
    },
  },
  type === 'patient' && {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    maxWidth: S.sizes.patientMaxWidth,

    '> header': {
      ...S.space.mb(3),
      [S.media.large]: S.space.mb(4),
    },
  },
]

export default Page
