import * as S from 'apps/cliniko/style'

const PageSections = props => <div css={styles} {...props} />

const styles = {
  display: 'flex',
  flexFlow: 'column',
  gap: S.unit(6),
}

export default PageSections
