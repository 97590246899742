import * as S from 'apps/cliniko/style'
import Portal from 'components/portal'
import Transition from 'components/transition'
import * as _ from 'modules/util'
import { useLayoutEffect } from 'react'

const Overlay = ({
  backgroundStyles: backgroundStylesIn,
  className,
  color = 'light',
  children,
  transition,
  when = true,
} = {}) => {
  useLayoutEffect(() => {
    if (!when) return
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'

    return () => {
      const scrollY = parseInt(document.body.style.top) * -1 || 0
      setTimeout(() => {
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, scrollY)
      }, transitionDuration)
    }
  }, [when])

  return (
    <Portal>
      <Transition
        css={[backgroundStyles({ color }), backgroundStylesIn]}
        duration={transitionDuration}
        enter={{ opacity: 1 }}
        from={{ opacity: 0 }}
        leave={{ opacity: 0 }}
        when={when}>
        <Transition css={className} {...transition} when={when}>
          {children}
        </Transition>
      </Transition>
    </Portal>
  )
}

const transitionDuration = 100

const backgroundStyles = ({ color }) => ({
  display: 'grid',
  zIndex: S.zIndex('app', 'modal'),
  height: '100%',
  width: '100%',
  background: S.rgba(S.colors.blue(-5), _.cond([color === 'light', 0.5], [color === 'dark', 0.9])),
  webkitTapHighlightColor: 'transparent',
  webkitOverflowScrolling: 'touch',
  overflowY: 'scroll',
  position: 'fixed',
  top: 0,
  left: 0,
  overflow: 'auto',
})

export default Overlay
