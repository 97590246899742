import { RouterProvider, createRootRoute, createRoute, createRouter } from '@tanstack/react-router'
import useCan from 'apps/cliniko/use-can'
import * as _ from 'modules/util'
import NotAuthorized from 'pages/not-authorized'
import * as R from 'ramda'

const Router = ({
  authorize,
  children,
  defaultErrorComponent,
  defaultNotFoundComponent: notFoundComponent,
  defaultOnError,
  defaultPendingComponent,
  routes: routeDefinitions,
  ...props
}) => {
  const can = useCan()

  const checkCondition = R.when(_.isFunction, _.applyTo({ can }))

  const enforceCondition = condition => () => {
    if (!checkCondition(condition)) throw routeConditionFailedError
  }

  const addErrorHandlingToComponent =
    ({ component, onError }) =>
    props => <ComponentWithErrorHandling {...props} component={component} onError={onError} />

  const buildRoute = (
    parentRoute,
    { beforeLoad, condition, errorComponent, onError, ...routeDefinition }
  ) => {
    const maybeEnforceCondition = authorize && enforceCondition(condition)

    return createRoute({
      ...routeDefinition,
      beforeLoad: _.actions(maybeEnforceCondition, beforeLoad),
      errorComponent: addErrorHandlingToComponent({
        component: errorComponent ?? defaultErrorComponent,
        onError: onError ?? defaultOnError,
      }),
      getParentRoute: () => parentRoute,
    })
  }

  const addChildRoutes = (parentRoute, routeDefinitions) => {
    const childRoutes = routeDefinitions.map(({ children, ...routeDefinition }) => {
      const route = buildRoute(parentRoute, routeDefinition)
      if (children) addChildRoutes(route, children)
      return route
    })
    return parentRoute.addChildren(childRoutes)
  }

  const rootRoute = createRootRoute({ component: () => children, notFoundComponent })
  const router = createRouter({
    ...props,
    defaultGcTime: 0,
    defaultPreloadStaleTime: 0,
    defaultPendingComponent,
    notFoundMode: 'root',
    routeTree: addChildRoutes(rootRoute, routeDefinitions),
  })

  return <RouterProvider router={router} />
}

const routeConditionFailedError = new Error('Route condition failed')

const ComponentWithErrorHandling = ({ component: Component, onError, ...props }) => {
  _.useEffectOnce(() => {
    onError?.(props.error)
  })

  if (props.error === routeConditionFailedError) {
    return <NotAuthorized {...props} />
  } else if (Component) {
    return <Component {...props} />
  } else {
    throw props.error
  }
}

export default Router
